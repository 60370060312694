import * as React from 'react';

import { st, classes } from './Price.st.css';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';

interface IPriceProps {
  price: string;
}

export class Price extends React.Component<IPriceProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;
  render() {
    return (
      <span
        data-hook="item-price"
        className={st(classes.root, { mobileView: this.context.isMobile })}
      >
        {this.props.price}
      </span>
    );
  }
}

import * as React from 'react';
import i18next from 'i18next';

import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import { Settings } from '@wix/search-results-settings-definitions';

import { ResultsStatus } from '../ResultsStatus';
import { SortControl, ISearchSortProps } from '../SortControl';
import { ITab } from '../../types/types';
import {
  getResultsFoundMessageKey,
  getResultsEmptyMessageKey,
} from '../resultsMessage';

import { classes, st } from './StatusLine.st.css';

type StatusLineProps = {
  sortProps: ISearchSortProps;
  visibleTabsWithTitles: ITab[];
  activeTabIndex?: number;
  settings: Settings;
  searchQuery?: string;
  isMobile: boolean;
  isFluid: boolean;
  // TODO: remove withoutSort when enableSorting experiment is merged
  withoutSort: boolean;
};

const getResultsText = ({
  count,
  settings,
  t,
  searchQuery = '',
}: {
  count: number;
  settings: {
    isResultsMessageWithNumber: boolean;
    isResultsMessageWithQuery: boolean;
    isResultsEmptyMessageWithNumber: boolean;
    isResultsEmptyMessageWithQuery: boolean;
  };
  t: i18next.TFunction;
  searchQuery: string;
}) => {
  const resultsMessageKey =
    count > 0
      ? getResultsFoundMessageKey({
          isWithNumber: settings.isResultsMessageWithNumber,
          isWithQuery: settings.isResultsMessageWithQuery,
        })
      : getResultsEmptyMessageKey({
          isWithNumber: settings.isResultsEmptyMessageWithNumber,
          isWithQuery: settings.isResultsEmptyMessageWithQuery,
        });

  return t(resultsMessageKey, {
    number: count,
    query: searchQuery,
  });
};

export const StatusLineComponent: React.FC<StatusLineProps &
  WithTranslation> = ({
  activeTabIndex = 0,
  isFluid,
  isMobile,
  searchQuery,
  settings,
  sortProps,
  t,
  visibleTabsWithTitles,
  withoutSort,
}) => {
  const count: number =
    (visibleTabsWithTitles.length > 0 &&
      visibleTabsWithTitles[activeTabIndex].count) ||
    0;

  return (
    <div className={st(classes.root, { mobileView: isMobile, fluid: isFluid })}>
      <ResultsStatus
        text={getResultsText({
          count,
          settings,
          t,
          searchQuery: searchQuery || '',
        })}
      />
      {count > 0 && !withoutSort && <SortControl {...sortProps} />}
    </div>
  );
};

export const StatusLine = withTranslation()(StatusLineComponent);

import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { withStyles } from '@wix/native-components-infra/dist/src/HOC/withStyles/withStyles';

import { buildSentryOptions } from './platform/monitoring/sentry';
import { WidgetRoot } from './components/WidgetRoot/WidgetRoot';

const sentryOptions = buildSentryOptions({
  environment: 'Viewer',
});

export default {
  component: withSentryErrorBoundary(
    withStyles(WidgetRoot, {
      cssPath: ['editorWidget.stylable.bundle.css'],
    }),
    sentryOptions,
  ),
};

// This file must export a default export object with "component" key

import * as React from 'react';

import { SearchResultsContext } from '../SearchResults/SearchResultsContext';
import { NotificationIcon } from './NotificationIcon';

import { st, classes } from './DemoContentNotification.st.css';

export interface IDemoContentNotificationProps {
  text: string;
}

export class DemoContentNotification extends React.Component<
  IDemoContentNotificationProps
> {
  static contextType = SearchResultsContext;
  context!: React.ContextType<typeof SearchResultsContext>;

  render() {
    const { text } = this.props;

    return (
      <div
        data-hook="demo-content-notification"
        className={st(classes.root, { mobileView: this.context.isMobile })}
      >
        <NotificationIcon className={classes.icon} />
        <span className={classes.text}>{text}</span>
      </div>
    );
  }
}

import { ignoreErrors } from '@wix/search-shared-libs';

import { ARTIFACT_VERSION } from '../../lib/constants';

const SENTRY_DSN =
  'https://f8bc65823c454879a705f3a0492636bf@sentry.wixpress.com/210';

interface ISentryConfig {
  enabled?: boolean;
  environment: 'Editor-mode component wrapper' | 'Worker' | 'Viewer';
  ignoreErrors?: string[];
  release?: string;
}

export interface ISentryOptions {
  dsn: string;
  config: ISentryConfig;
}

export type IReportError = (
  error: Error | ErrorEvent | string,
  options: {
    tags: {
      ssr: boolean;
      mobile: boolean;
      fluid: boolean | 'unknown'; // TODO: remove 'unknown' when searchAppController knows this value
      demo: boolean;
    };
  },
) => void;

export const buildSentryOptions = (config: ISentryConfig): ISentryOptions => {
  return {
    dsn: SENTRY_DSN,
    config: {
      enabled: process.env.NODE_PATH === 'production',
      release: ARTIFACT_VERSION,
      ignoreErrors,
      ...config,
    },
  };
};

import * as React from 'react';

import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import { Tabs, ALIGNMENT, TabsProps } from 'wix-ui-tpa/Tabs';

import { ITab } from '../../types/types';

type TabLineProps = {
  activeTabIndex?: number;
  alignment?: ALIGNMENT;
  items: ITab[];
  onTabClick: TabsProps['onTabClick'];
};

const TabLineComponent: React.FC<TabLineProps & WithTranslation> = ({
  activeTabIndex,
  alignment = ALIGNMENT.left,
  items,
  onTabClick,
  t,
}) => {
  return (
    <Tabs
      activeTabIndex={activeTabIndex}
      alignment={alignment}
      aria-label={t('searchResults.tabs.menu.accessibilityLabel')}
      data-hook="tabs"
      items={items}
      onTabClick={onTabClick}
    />
  );
};

export const TabLine = withTranslation()(TabLineComponent);

import * as React from 'react';
import { isEqual } from 'lodash';

import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
// TODO: research why all sdk code is included in bundle.

import {
  ISearchDocument,
  SearchDocumentType,
  ISearchDocumentType,
} from '@wix/client-search-sdk';

import { GridLayout } from '../GridLayout';
import { ListLayout } from '../ListLayout';
import { SampleLayout } from '../SampleLayout';
import { EventList } from '../EventList';

import { SearchResults } from '../SearchResults';

import { ISearchSortProps } from '../SortControl';
import { IWidgetProps, IWidgetState } from './Widget.types';
import { SearchRequestStatus, ITab } from '../../types/types';

import { ISearchSortConfig } from '../../platform/searchResultsControllerStore';
import {
  buildSearchResultsUrl,
  toLocationSearchRequest,
} from '../../platform/location';
import {
  getDateFormatter,
  getTimeFormatter,
  getCurrencyFormatter,
} from '../../lib/formatters/get-formatters';
import {
  DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT,
  DOCUMENT_TYPE_TRANSLATIONS,
  DOCUMENT_TYPE_ACCESSIBILITY_LABEL,
} from '../documentTypeTranslations';
import { IListLayoutProps } from '../Layout.types';
import { demoTotals, listLayoutBreakPoint } from './Widget.constants';

export class WidgetComponent extends React.Component<
  WithTranslation & IWidgetProps,
  IWidgetState
> {
  state = {
    isWideListThumbnails: this.isWideListThumbnails(),
    searchQuery: this.props.searchRequest.query,
  };

  listRef: React.RefObject<HTMLUListElement> = React.createRef();

  getTabTitleWithCount = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const titleOverride =
      this.props.settings.categoryList[documentType].useOverride &&
      this.props.settings.categoryList[documentType].override;

    if (titleOverride) {
      return `${titleOverride} (${count})`;
    }

    const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT[documentType];
    return titleI18NKey
      ? this.props.t(titleI18NKey, {
          number: count,
        })
      : documentType;
  };

  getTabTitleWithoutCount = (documentType: SearchDocumentType): string => {
    const titleOverride =
      this.props.settings.categoryList[documentType].useOverride &&
      this.props.settings.categoryList[documentType].override;

    if (titleOverride) {
      return titleOverride;
    }

    const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS[documentType];
    return titleI18NKey ? this.props.t(titleI18NKey) : documentType;
  };

  getAccessibilityLabelForDocumentType = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const { t } = this.props;
    let title = documentType;

    const titleI18NKey = DOCUMENT_TYPE_ACCESSIBILITY_LABEL[documentType];

    if (titleI18NKey) {
      title = t(titleI18NKey, {
        number: count,
      });
    }

    return title;
  };

  createTab = ({ documentType }: ISearchDocumentType): ITab => {
    const { searchResponseTotals, isDemoContent } = this.props;

    const searchResponseTotalCounts = isDemoContent
      ? demoTotals
      : searchResponseTotals;

    const count =
      searchResponseTotalCounts && searchResponseTotalCounts[documentType];

    return {
      documentType,
      visible: this.props.settings.categoryList[documentType].visible,
      title: count
        ? this.getTabTitleWithCount(documentType, count)
        : this.getTabTitleWithoutCount(documentType),
      count: count || 0,
    };
  };

  componentDidMount() {
    const isWideListThumbnails = this.isWideListThumbnails();
    if (this.state.isWideListThumbnails !== isWideListThumbnails) {
      this.setState({ isWideListThumbnails });
    }
  }

  isWideListImagesEnabled(): boolean {
    const { isFluid, searchRequest } = this.props;
    return (
      isFluid && searchRequest?.documentType !== SearchDocumentType.Products
    );
  }

  isWideListThumbnails(): boolean {
    if (this.isWideListImagesEnabled() && this.listRef?.current) {
      return this.listRef.current.offsetWidth <= listLayoutBreakPoint;
    }
    return false;
  }

  componentDidUpdate(prevProps: IWidgetProps) {
    // TODO: review viewMode when resolved https://github.com/wix-private/native-components-infra/pull/28
    if (
      prevProps.searchRequest.query !== this.props.searchRequest.query ||
      prevProps.viewMode !== this.props.viewMode
    ) {
      this.setState({
        searchQuery: this.props.searchRequest.query,
      });
    }

    if (
      this.listRef.current &&
      !isEqual(prevProps.searchRequest, this.props.searchRequest)
    ) {
      // safari (as of 2019-11-18) ignores `preventScroll`:
      // https://bugs.webkit.org/show_bug.cgi?id=178583
      const safariCantPreventScrollX = window.scrollX;
      const safariCantPreventScrollY = window.scrollY;

      this.listRef.current.focus({
        preventScroll: true,
      });

      if (
        safariCantPreventScrollX !== window.scrollX ||
        safariCantPreventScrollY !== window.scrollY
      ) {
        window.scrollTo(safariCantPreventScrollX, safariCantPreventScrollY);
      }
    }
  }

  getTotalPages(pageSize: number, total: number | null): number {
    if (total) {
      return Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1);
    }
    return 0;
  }

  handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleQueryClear = () => {
    this.setState({ searchQuery: '' }, () => {
      this.handleSubmit();
    });
  };

  handlePageChange = (v: {
    event: React.MouseEvent<HTMLElement>;
    page: number;
  }) => {
    const { event, page } = v;

    if (shouldKeepDefaultBehaviour(event)) {
      event.stopPropagation();
      return;
    }

    const { onPageChange } = this.props;

    onPageChange(page);
  };

  handleTabChange = (
    tabs: Array<ITab & { title: string }>,
    tabIndex: number,
  ) => {
    const { onDocumentTypeChange } = this.props;
    onDocumentTypeChange(tabs[tabIndex].documentType, {
      source: 'tabs',
    });
  };

  handleSamplesViewAllClick = (
    e: React.MouseEvent<HTMLElement>,
    documentType: SearchDocumentType,
  ) => {
    if (shouldKeepDefaultBehaviour(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    const { onDocumentTypeChange } = this.props;

    onDocumentTypeChange(documentType, {
      source: 'samples',
    });
  };

  handleSubmit = () => {
    const { onQuerySubmit } = this.props;
    const { searchQuery } = this.state;

    onQuerySubmit(searchQuery);
  };

  handleSearchResultClick = (
    e: React.MouseEvent<HTMLElement>,
    item: ISearchDocument,
    index: number,
  ) => {
    if (shouldKeepDefaultBehaviour(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    this.props.onDocumentClick(item, index);
  };

  buildPageUrl = (page: number): string => {
    const locationRequest = {
      ...toLocationSearchRequest(this.props.searchRequest),
      page,
    };
    return buildSearchResultsUrl(
      this.props.searchResultsAbsoluteUrl,
      locationRequest,
    );
  };

  getSortProps = (sortConfig: ISearchSortConfig): ISearchSortProps => {
    const { t } = this.props;
    return {
      ...sortConfig,
      sortOptions: sortConfig?.sortOptions.map(sortOption => ({
        ...sortOption,
        value: t(sortOption.valueKey),
      })),
      selectLabel: t('searchResults.sort.dropdown.label'),
    };
  };

  buildDocuments = documents => {
    const { isDemoContent, t } = this.props;

    return isDemoContent
      ? documents.map(document => {
          return Object.entries(document).reduce(
            (acc, [key, value]: [string, any]) => {
              acc[key] =
                typeof value === 'string' &&
                value.startsWith('mockedSearchResults.')
                  ? t(value)
                  : value;
              return acc;
            },
            {},
          );
        })
      : documents;
  };

  buildItems = items => {
    const { isDemoContent } = this.props;

    return isDemoContent
      ? items.map(item => ({
          ...item,
          documents: this.buildDocuments(item.documents),
        }))
      : items;
  };

  render() {
    const {
      documentTypes,
      isDemoContent,
      isFluid,
      isMobile,
      locale,
      reportError,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      searchSamples,
      settings,
      t,
      apiErrorDetails,
    } = this.props;

    const { searchQuery, isWideListThumbnails } = this.state;

    const totalPages = this.getTotalPages(
      searchRequest.paging.pageSize,
      searchResponse.totalResults,
    );

    // TODO: resolve Alignment vs TabAlignment
    const resultsMenuAlignment = settings.resultsMenuAlignment as any;
    const paginationAlignment = settings.paginationAlignment;
    const categoryList = settings.categoryList;

    // TODO: remove when the widget starts to use SSR-rendered screen instead of loading indicator
    if (!categoryList) {
      return <div />;
    }

    const visibleTabsWithTitles = documentTypes
      .map(this.createTab)
      .filter(tab => categoryList[tab.documentType].visible)
      .sort(
        (a, b) =>
          categoryList[a.documentType].index -
          categoryList[b.documentType].index,
      );

    const totalCount = visibleTabsWithTitles
      .filter(tab => tab.documentType !== SearchDocumentType.All)
      .reduce((total, tab) => {
        return total + tab.count;
      }, 0);

    const allTab = visibleTabsWithTitles.find(
      tab => tab.documentType === SearchDocumentType.All,
    );

    if (allTab?.visible) {
      allTab.title =
        totalCount > 0
          ? this.getTabTitleWithCount(SearchDocumentType.All, totalCount)
          : this.getTabTitleWithoutCount(SearchDocumentType.All);
    }

    const index: number =
      visibleTabsWithTitles.findIndex(
        tab => tab.documentType === searchRequest.documentType,
      ) || 0;
    const activeTabIndex: number = index >= 0 ? index : 0;

    const shouldShowSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const samplesWithTitles = shouldShowSamples
      ? searchSamples
          .map(s => ({
            ...s,
            accessibilityLabel: searchResponseTotals
              ? this.getAccessibilityLabelForDocumentType(
                  s.documentType,
                  searchResponseTotals[s.documentType] || 0,
                )
              : this.getTabTitleWithoutCount(s.documentType),
            title: searchResponseTotals
              ? this.getTabTitleWithCount(
                  s.documentType,
                  searchResponseTotals[s.documentType] || 0,
                )
              : this.getTabTitleWithoutCount(s.documentType),
          }))
          .filter(sample => categoryList[sample.documentType].visible)
      : [];

    function handleException(ex) {
      reportError(ex);
      console.error(ex);
    }

    const documentType = searchRequest.documentType || SearchDocumentType.All;

    const listProps: IListLayoutProps = {
      formatCurrency: getCurrencyFormatter(locale, handleException),
      formatDate: getDateFormatter(locale, handleException),
      formatTime: getTimeFormatter(locale, handleException),
      isWideThumbnail: isWideListThumbnails,
      items: this.buildDocuments(searchResponse.documents),
      listRef: this.listRef,
      onItemLinkClick: this.handleSearchResultClick,
      label: searchResponseTotals
        ? t(DOCUMENT_TYPE_ACCESSIBILITY_LABEL[documentType], {
            number: searchResponseTotals[documentType],
          })
        : t(DOCUMENT_TYPE_TRANSLATIONS[documentType]),
      t,
    };

    const getListLayout = () => <ListLayout {...listProps} />;

    const failed = searchRequestStatus === SearchRequestStatus.Failed;
    return (
      <SearchResults
        searchQuery={
          isDemoContent ? t('resultsFoundMessage.demoQuery') : searchQuery
        }
        searchPlaceholder={settings.searchBarPlaceholder}
        searchClearLabel={t('searchResults.clearLabel')}
        isSearchBarEnabled={settings.isSearchBarEnabled}
        totalCount={isDemoContent ? 195 : totalCount}
        tabsAlignment={resultsMenuAlignment}
        activeTabIndex={activeTabIndex}
        onTabChange={(tabIndex: number) =>
          this.handleTabChange(visibleTabsWithTitles, tabIndex)
        }
        onQueryChange={this.handleQueryChange}
        onQueryClear={this.handleQueryClear}
        onSubmit={this.handleSubmit}
        isPaginationHidden={shouldShowSamples}
        currentPage={searchRequest.paging.page}
        totalPages={totalPages > 1 ? totalPages : null}
        paginationAlignment={paginationAlignment}
        onPageChange={this.handlePageChange}
        isLoading={searchRequestStatus === SearchRequestStatus.Loading}
        isMobile={isMobile}
        isDemoContent={isDemoContent}
        demoContentNotificationText={t(
          'searchResults.demoContentNotificationText',
        )}
        buildPageUrl={this.buildPageUrl}
        sortProps={this.getSortProps(this.props.sortConfig)}
        visibleTabsWithTitles={visibleTabsWithTitles}
        settings={this.props.settings}
        lastQuery={this.props.searchRequest.query}
        failed={failed}
        isFluid={isFluid}
        enableSortingExperiment={this.props.enableSortingExperiment}
        apiErrorDetails={apiErrorDetails}
      >
        {(() => {
          switch (searchRequest.documentType) {
            case SearchDocumentType.All:
              return shouldShowSamples ? (
                <SampleLayout
                  {...listProps}
                  results={this.buildItems(samplesWithTitles)}
                  onViewAllClick={this.handleSamplesViewAllClick}
                />
              ) : (
                getListLayout()
              );
            case SearchDocumentType.Products:
              return <GridLayout {...listProps} />;
            case SearchDocumentType.Events:
              return <EventList {...listProps} />;

            default:
              return getListLayout();
          }
        })()}
      </SearchResults>
    );
  }
}

export const Widget = withTranslation()(WidgetComponent);

function isAnchorElement(element: HTMLElement): element is HTMLAnchorElement {
  return element.nodeName === 'A';
}

// handle opening links in a new tab
// https://github.com/zeit/next.js/blob/42771f6451df6ba2dbda51e60968e9edcfde74af/packages/next/client/link.tsx#L149-L159
function shouldKeepDefaultBehaviour(e: React.MouseEvent<HTMLElement>): boolean {
  const currentTarget = e.currentTarget;
  return (
    isAnchorElement(currentTarget) &&
    ((currentTarget.target && currentTarget.target !== '_self') ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey ||
      (e.nativeEvent && e.nativeEvent.button === 1))
  );
}

import * as React from 'react';
import { getWixImageUrl } from '../getWixImageUrl';

import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';

import { Thumbnail } from '../Thumbnail';
import { Title } from '../Title';
import { Price } from '../Price';

import { st, classes } from './GridLayoutItem.st.css';
import { ILayoutItemProps } from '../Layout.types';

const THUMBNAIL_WIDTH = {
  DESKTOP: 211,
  MOBILE: 130,
};

const getThumbnailWidth = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_WIDTH.MOBILE : THUMBNAIL_WIDTH.DESKTOP;

const THUMBNAIL_HEIGHT = {
  DESKTOP: 174,
  MOBILE: 105,
};

const getThumbnailHeight = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_HEIGHT.MOBILE : THUMBNAIL_HEIGHT.DESKTOP;

interface IGridLayoutItemProps extends ILayoutItemProps {}

export class GridLayoutItem extends React.Component<IGridLayoutItemProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  render() {
    const { isMobile, isFluid } = this.context;
    const { item, onClick, onLinkClick } = this.props;

    return (
      <li
        data-hook="grid-layout-item"
        role="listitem"
        className={st(classes.root, { mobileView: isMobile, fluid: isFluid })}
        onClick={onClick}
      >
        <Thumbnail
          title={item.title}
          onClick={onLinkClick}
          url={item.url}
          imageUrl={getWixImageUrl(
            item.image,
            getThumbnailWidth(isMobile),
            getThumbnailHeight(isMobile),
          )}
        />
        <div>
          <Title title={item.title} onClick={onLinkClick} url={item.url} />
        </div>
        {'price' in item && item.price && <Price price={item.price} />}
      </li>
    );
  }
}

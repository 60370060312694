import * as React from 'react';

import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import { IApiError } from '@wix/client-search-sdk';

import { getErrorMessageKey } from './getErrorMessageKey';

import { st, classes } from './ResultsStatus.st.css';
import statusLineStyles from '../StatusLine/StatusLine.st.css';

type FailedMessageProps = {
  isMobile: boolean;
  apiErrorDetails?: IApiError;
};

export const FailedMessageComponent: React.FC<FailedMessageProps &
  WithTranslation> = ({ isMobile, t, apiErrorDetails }) => {
  const errorMessageKey = getErrorMessageKey(apiErrorDetails);
  return (
    <div
      className={statusLineStyles.st(statusLineStyles.classes.root, {
        mobileView: isMobile,
      })}
    >
      <p
        className={st(classes.root, { isMobile })}
        data-error-message-key={errorMessageKey}
        data-hook="search-error-message"
      >
        {t(errorMessageKey, {
          error_code: apiErrorDetails?.code,
          error_title: apiErrorDetails?.message,
          requestID: apiErrorDetails?.requestId,
        })}
      </p>
    </div>
  );
};

export const FailedMessage = withTranslation()(FailedMessageComponent);

import * as React from 'react';

import { InputProps, Input } from 'wix-ui-core/dist/src/components/input';
import { InputWithOptions } from 'wix-ui-core/input-with-options';

import { ClearButton } from '../ClearButton';
import { SearchIcon } from '../SearchIcon';
import { ISearchBoxProps } from './SearchBox.types';
import { SearchResultsContext } from '../SearchResults/SearchResultsContext';

import { st, classes } from './SearchBox.st.css';

export class SearchBox extends React.Component<ISearchBoxProps> {
  inputRef: Input;
  state = {
    isInputFocused: false,
  };

  static contextType = SearchResultsContext;
  context!: React.ContextType<typeof SearchResultsContext>;

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.props.onSubmit(e);
    this.inputRef.blur();
  };

  handleInputContainerClick = () => {
    this.inputRef.focus();
  };

  handleInputRef = (ref: Input) => {
    this.inputRef = ref;
  };

  getInputProps(): InputProps & { ref(ref: Input): void } {
    return {
      'aria-label': this.props.placeholder,
      type: 'search',
      value: this.props.value,
      onChange: this.props.onChange,
      placeholder: this.props.placeholder,
      name: 'search',
      autoComplete: 'off',
      ref: this.handleInputRef,
      prefix: <SearchIcon className={classes.icon} data-hook="search-icon" />,
      suffix: !!this.props.value && (
        <ClearButton
          onClick={this.props.onClear}
          data-hook="clear-button"
          aria-label="{clearLabel}"
        />
      ),
      onFocus: () => this.setState({ isInputFocused: true }),
      onBlur: () => this.setState({ isInputFocused: false }),
    };
  }

  render() {
    return (
      <form
        action="#"
        className={st(classes.root, {
          mobileView: this.context.isMobile,
          inputFocused: this.state.isInputFocused,
        })}
        data-hook="search-box-form"
        onSubmit={this.handleSubmit}
        role="search"
      >
        <div
          onClick={this.handleInputContainerClick}
          data-hook="input-with-options-container"
          className={classes.inputContainer}
        >
          <InputWithOptions
            className={classes.input}
            data-hook="input-with-options"
            id="search-box-in-widget-inputwithoptions"
            inputProps={this.getInputProps()}
            options={[]}
          />
        </div>
      </form>
    );
  }
}

export default SearchBox;

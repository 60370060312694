import * as React from 'react';

import { classes } from './ViewAllButton.st.css';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';

interface IViewAllButtonProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  url: string;
  'data-hook'?: string;
}

const ViewAllButtonComponent: React.FunctionComponent<IViewAllButtonProps &
  WithTranslation> = props => {
  const label = props.t('searchResults.samples.label.viewAll');
  return (
    <a
      title={label}
      href={props.url}
      data-hook={props['data-hook'] || 'view-all-button'}
      onClick={props.onClick}
      className={classes.root}
      role="button"
    >
      {label}
    </a>
  );
};

export const ViewAllButton = withTranslation()(ViewAllButtonComponent);

import * as React from 'react';

import CloseIcon from 'wix-ui-icons-common/system/Close';
import { ButtonNext } from 'wix-ui-core/button-next';

import { classes } from './ClearButton.st.css';

export interface IClearButtonProps {
  onClick?(): void;
  ['aria-label']?: string;
}

export const ClearButton: React.FunctionComponent<IClearButtonProps> = ({
  onClick,
  ...props
}) => {
  return (
    <ButtonNext
      {...props}
      className={classes.root}
      onClick={onClick}
      data-hook="clear-button"
    >
      <CloseIcon width={'8px'} height={'8px'} />
    </ButtonNext>
  );
};

import * as React from 'react';

export interface INotificationIconProps
  extends React.HTMLAttributes<SVGElement> {}

export const NotificationIcon: React.FunctionComponent<INotificationIconProps> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="2 2 22 22"
      {...props}
    >
      <g fill="none" stroke="currentColor">
        <path d="M12 20.5a8.5 8.5 0 110-17 8.5 8.5 0 010 17zm0 0a8.5 8.5 0 100-17 8.5 8.5 0 000 17zM12 7.02v6.983m0 2.007v.977" />
      </g>
    </svg>
  );
};

import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { WIX_SITE_SEARCH } from '@wix/app-definition-ids';

import { ISearchLocation } from './location.types';
import { decodePath, encodePath } from './path';
import { DEFAULT_SEARCH_REQUEST } from './params/defaultSearchRequest';

const SEARCH_RESULTS_PAGE_ID = 'search_results';

export const toLocationSearchRequest: ISearchLocation['toLocationSearchRequest'] = ({
  paging,
  ...searchRequest
}) => {
  return {
    ...searchRequest,
    page: paging.page,
  };
};

export const buildSearchResultsUrl: ISearchLocation['buildSearchResultsUrl'] = (
  searchResultsAbsoluteUrl,
  locationSearchRequest,
) => {
  return `${searchResultsAbsoluteUrl}/${encodePath(locationSearchRequest)}`;
};

export const createSearchLocation = (wixCodeApi: IWixAPI): ISearchLocation => {
  function getSectionUrl() {
    return wixCodeApi.site.getSectionUrl({
      appDefinitionId: WIX_SITE_SEARCH,
      sectionId: SEARCH_RESULTS_PAGE_ID,
    });
  }

  return {
    decodePath,
    encodePath,

    getSearchResultsRelativeUrl: async () => {
      const { relativeUrl } = await getSectionUrl();
      if (typeof relativeUrl !== 'string') {
        throw new Error(
          'Unexpected value returned from wixCodeApi.site.getSectionUrl().',
        );
      }
      return relativeUrl;
    },

    getSearchResultsAbsoluteUrl: async () => {
      const { url } = await getSectionUrl();
      if (typeof url !== 'string') {
        throw new Error(
          'Unexpected value returned from wixCodeApi.site.getSectionUrl().',
        );
      }
      return url;
    },

    navigateToSearchResults: async searchRequest => {
      const { relativeUrl } = await getSectionUrl();
      wixCodeApi.location.to?.(`${relativeUrl}/${encodePath(searchRequest)}`);
    },

    toLocationSearchRequest,

    toSDKSearchRequest: ({ page, ...searchRequest }, pageSize) => {
      return {
        ...searchRequest,
        paging: {
          page: page || DEFAULT_SEARCH_REQUEST.page,
          pageSize,
        },
      };
    },

    buildSearchResultsUrl,
  };
};

import * as React from 'react';

import { ISearchEventDocument, EventType } from '@wix/client-search-sdk';

import { Thumbnail } from '../Thumbnail';
import { IListLayoutItemProps } from '../Layout.types';
import { Title } from '../Title';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';
import { getWixImageUrl } from '../getWixImageUrl';

import { classes as descriptionClasses } from '../Description/Description.st.css';
import { st, classes } from './EventListItem.st.css';

const THUMBNAIL_WIDTH = 176;
const THUMBNAIL_HEIGHT = 124;

export const enum EventListItemRenderMode {
  default = 'default',
  condensed = 'condensed',
}

interface IEventListItemProps extends IListLayoutItemProps {
  renderMode?: EventListItemRenderMode;
}

export class EventListItem extends React.Component<IEventListItemProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  static defaultProps = {
    renderMode: EventListItemRenderMode.default,
  };

  renderTime({ startDate }: ISearchEventDocument, formatDate, formatTime) {
    return startDate ? (
      <div data-hook="event-start" className={classes.eventDateTime}>
        <span data-hook="event-start-date">{formatDate(startDate)}</span>
        <span>&nbsp;|&nbsp;</span>
        <span data-hook="event-start-time">{formatTime(startDate)}</span>
      </div>
    ) : null;
  }

  getTranslatedEventTypeLabel({
    eventType,
    minPrice,
    maxPrice,
    currency,
  }: ISearchEventDocument) {
    const { formatCurrency, t } = this.props;

    const translation = {
      [EventType.paid]: () => {
        const translationKey =
          minPrice !== maxPrice
            ? 'searchResults.list.events.eventTypeLabel.paid'
            : 'searchResults.list.events.eventTypeLabel.paid.singlePrice';
        return currency
          ? t(translationKey, {
              minPrice: formatCurrency(minPrice, currency),
              maxPrice: formatCurrency(maxPrice, currency),
            })
          : undefined;
      },
    }[eventType];
    return translation ? translation() : undefined;
  }

  getEventType(event: ISearchEventDocument) {
    return this.getTranslatedEventTypeLabel(event);
  }

  renderEventType(event: ISearchEventDocument) {
    const translation = this.getEventType(event);
    if (!translation) {
      return null;
    }
    return (
      <div className={classes.eventType} data-hook="event-type">
        {translation}
      </div>
    );
  }

  renderDescription() {
    const { renderMode, item, formatDate, formatTime } = this.props;

    const event = item as ISearchEventDocument;
    return (
      <div className={descriptionClasses.root}>
        {this.renderTime(event, formatDate, formatTime)}
        <div
          className={classes.eventLocation}
          data-hook="event-location"
          dangerouslySetInnerHTML={{ __html: event.location }}
        />
        {renderMode === EventListItemRenderMode.default &&
          this.renderEventType(event)}
      </div>
    );
  }

  renderTitle = () => {
    const { renderMode, onClick, item } = this.props;
    const title = <Title title={item.title} onClick={onClick} url={item.url} />;
    const eventType = this.getEventType(item as ISearchEventDocument);
    return renderMode === EventListItemRenderMode.default ? (
      title
    ) : (
      <div>
        <div className={classes.condensedTitle}>
          <span
            className={st(classes.condensedTitleStrong, {
              narrow: !!eventType,
            })}
          >
            {title}
          </span>
          <div
            className={st(classes.condensedTitleEvent)}
            data-hook="event-type"
          >
            {eventType}
          </div>
        </div>
      </div>
    );
  };

  getImageUrl(): string {
    const {
      item,
      thumbnailHeight = THUMBNAIL_HEIGHT,
      thumbnailWidth = THUMBNAIL_WIDTH,
    } = this.props;

    return item.image &&
      'name' in item.image &&
      item.image.name.startsWith('http')
      ? item.image.name
      : getWixImageUrl(item.image, thumbnailWidth, thumbnailHeight) || '';
  }

  render() {
    const { item, onClick, isWideThumbnail } = this.props;

    return (
      <li
        className={st(classes.root, {
          fixed: !this.context.isFluid,
          fluid: this.context.isFluid,
          mobileView: this.context.isMobile,
        })}
        data-hook="list-layout-item"
        onClick={onClick}
        role="listitem"
      >
        {!this.context.isMobile && (
          <Thumbnail
            imageUrl={this.getImageUrl()}
            isWide={isWideThumbnail}
            onClick={onClick}
            title={item.title}
            url={item.url}
          />
        )}
        <div className={classes.content}>
          {this.renderTitle()}
          {this.renderDescription()}
        </div>
      </li>
    );
  }
}

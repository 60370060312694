import * as React from 'react';

import { SearchResultsContext } from '../SearchResults/SearchResultsContext';

import { st, classes } from './ResultsStatus.st.css';

interface IResultsStatus {
  text: string;
}

export class ResultsStatus extends React.Component<IResultsStatus> {
  static contextType = SearchResultsContext;
  context!: React.ContextType<typeof SearchResultsContext>;

  render() {
    return (
      <p
        className={st(classes.root, { mobileView: this.context.isMobile })}
        data-hook="total-results"
      >
        {this.props.text}
      </p>
    );
  }
}

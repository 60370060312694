import * as React from 'react';
import { get } from 'lodash';

import { I18nextProvider, initI18n } from '@wix/wix-i18n-config';

import { Widget } from '../Widget/Widget';
import { IWidgetRootProps } from './WidgetRoot.types';
import { addHighlightStyle, HighlightStyle } from './addHighlightStyle';
import { applyWhitelist } from './applyWhitelist';
export class WidgetRoot extends React.PureComponent<IWidgetRootProps> {
  componentDidMount() {
    this.props.host?.appLoadBI.loaded();
    this.props.host?.registerToComponentDidLayout(this.props.onAppLoaded);
  }

  render() {
    const {
      apiErrorDetails,
      enableSortingExperiment,
      isDemoContent,
      isFluid,
      isMobile,
      language,
      onDocumentClick,
      onDocumentTypeChange,
      onPageChange,
      onQuerySubmit,
      reportError,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      searchResultsAbsoluteUrl,
      settings,
      sortConfig,
      translations,
      viewMode,
    } = this.props;

    console.groupCollapsed('WidgetRoot:render');
    console.log('props', this.props);
    console.log('settings', settings);
    console.groupEnd();

    if (!language || !translations) {
      return null;
    }

    const { documentTypes, searchSamples } = applyWhitelist({
      documentTypes: this.props.documentTypes,
      searchSamples: this.props.searchSamples,
    });

    const highlightStyle: HighlightStyle = get(
      this.props,
      [
        'host',
        'style',
        'styleParams',
        'fonts',
        'resultsHighlightFont',
        'style',
      ],
      {
        bold: false,
        italic: false,
        underline: false,
      },
    );

    this.props.searchResponse.documents.forEach(document => {
      document.title = addHighlightStyle(document.title, highlightStyle);
      document.description = addHighlightStyle(
        document.description,
        highlightStyle,
      );
    });

    searchSamples.forEach(sample =>
      sample.documents.forEach(document => {
        document.title = addHighlightStyle(document.title, highlightStyle);
        document.description = addHighlightStyle(
          document.description,
          highlightStyle,
        );
      }),
    );

    return (
      <I18nextProvider
        i18n={initI18n({
          locale: language,
          messages: translations,
        })}
      >
        <div className={this.props.id}>
          <Widget
            apiErrorDetails={apiErrorDetails}
            documentTypes={documentTypes}
            enableSortingExperiment={enableSortingExperiment}
            isDemoContent={isDemoContent}
            isFluid={isFluid}
            isMobile={isMobile}
            locale={language}
            onDocumentClick={onDocumentClick}
            onDocumentTypeChange={onDocumentTypeChange}
            onPageChange={onPageChange}
            onQuerySubmit={onQuerySubmit}
            reportError={reportError}
            searchRequest={searchRequest}
            searchRequestStatus={searchRequestStatus}
            searchResponse={searchResponse}
            searchResponseTotals={searchResponseTotals}
            searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
            searchSamples={searchSamples}
            settings={settings}
            sortConfig={sortConfig}
            viewMode={viewMode}
          />
        </div>
      </I18nextProvider>
    );
  }
}
